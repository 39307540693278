import './ContactPersonComponent.css';



function ContactPersonComponent(props) {
    return (
        <div className="contactContainer">
            <div className="contactImageBox">
                <img src={props.contactPerson} alt={"person for contact"} height="100%" width="100%"/>
                <img src={props.contactQRC} alt={"QR code for contact"} height="100%" width="100%"/>
            </div>
            <div className="contentBox">
                <span className="contactName">{props.name}</span>
                <div className="contactPosition">{props.position}</div>
                <a href={"tel:" + props.tel} className="contactInfoText">{props.tel}</a>
                <a href={"mailto:" + props.email} className="contactInfoText">{props.email}</a>
            </div>
        </div>
    );
}

export default ContactPersonComponent;