import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Homepage from "./Homepage";
import './fonts/Montserrat-Regular.ttf';
import './fonts/Montserrat-Bold.ttf';
import './fonts/DirectorsGothic-Semibold.otf';
import {ParallaxProvider} from "react-scroll-parallax";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <ParallaxProvider>
            <Homepage />
      </ParallaxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
