import './Homepage.css';
import { useRef, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import logo from './images/OM_full_light.svg'
import plus from './icons/plus.svg'
import shoppingCart from './icons/shoppingCart.svg'
import arrowRight from './icons/arrowRightWhite.png'
import arrowRightPrimary from './icons/arrowRightPrimary.png'
import ReferenceContainer from "./ReferenceContainer";
import ReferenceContainerReverse from "./ReferenceContainerReverse";
import uMateje from './images/Logo_U_mateje_vysivka-1.png'
import cukrar from './images/Cukrar logo negativ pro tisk BLACK.png'
import MSP from './images/Mama Shelter Prague.png'
import McD from './images/McD_black_500x500px.png'
import O2Arena from './images/O2arena_LOGO_CMYK.png'
import O2Universum from './images/O2universum_LOGO_CMYK.jpg'
import stagesHotel from './images/STAGES-HOTEL_logo_horizontalni_pozitivni_vektor_RGB.png'
import courtyard from './images/courtyard.png'
import marriott from './images/Marriott.svg'
import cervenyJelen from './images/cervenyJelen.png'
import ssg from './images/SSG.svg'
import kkHotels from './images/k+kHotels.png'
import trautenberk from './images/pivovarTrautenberk.png'
import contactQRCPolacek from './images/qr_polacek.svg'
import contactQRCRosin from './images/qr_rosin.svg'
import contactPerson from './images/rosin.png'
import contactPerson2 from './images/polacek.png'
import ContactPersonComponent from "./ContactPersonComponent";
import helpImg from './images/help.svg'
import close from './icons/close.png'
import menu from './icons/menu.svg'
import hotlineLight from './images/hotline_light.svg'
import hotlineDark from './images/hotline_dark.svg'
import bubblesLogo from './icons/OM_icon_mono_light.svg'
import vop from './documents/OM-VOP-A4.pdf'
import gdpr from './documents/OM-GDPR-A4.pdf'
import bubble1 from './images/bubble1.png'
import bubble2 from './images/bubble2.png'
import bubble3 from './images/bubble3.png'
import bubble4 from './images/bubble4.png'
import bubble5 from './images/bubble5.png'
import bubble6 from './images/bubble6.png'
import {useParallax, useParallaxController} from 'react-scroll-parallax';
import useKeypress from 'react-use-keypress';
import {wait} from "@testing-library/user-event/dist/utils";


const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
        element.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
            clearInterval(slideTimer);
        }
    }, speed);
};


function Homepage() {
    const services = {"odbornost":{"title": "ODBORNOST", "text1": "Jsme odborníci v oblasti profesionálního mytí nádobí. Vnímáme potřeby našich klientů a volíme vhodná řešení pro každý gastro provoz. Postaráme se, aby vaše nádobí bylo vždy zářivě čisté a mycí technika šlapala jako hodinky dlouhá léta. ", "strong": "Mytí nás prostě baví a rádi vám s ním pomůžeme.", "text2":" Umíme poradit s výběrem vhodného stroje dle konkrétní potřeby. Nastavit celý proces mytí pro jeho bezproblémové fungování je pro nás hračka. Starat se dlouhodobě o chod mycích zařízení je naše radost. Nečekané poruchy snadno vyřešíme. Poradíme, posloužíme, nastavíme, proškolíme. :)"},
    "prostredky":{"title": "PROSTŘEDKY", "text1": "", "strong": "O dokonale čisté nádobí se postarají naše prostředky s ideálně vyváženým složením. ", "text2": "Ať už potřebujete nádobí umýt, opláchnout nebo vyleštit, na našem e-shopu najdete všepotřebné. Myslíme i na hygienu mycího stroje, protože jenom čistá myčka je cestou kdokonale čistému nádobí. Můžete pravidelně manuálně čistit její jednotlivé části nebo jednoduše použít naše hygienické tablety, které všechnu práci udělají za vás. A abyste se nemuseli se vší tou parádou tahat, připravili jsme pro vás i praktický vozík s logem Odborné myčkové."},
    "voda":{"title": "VODA", "text1": "Velmi důležitá, přesto často opomíjená součást mycího procesu. I voda prostě musí být akorát. Je to trochu alchymie, ale my naštěstí přesně víme, co a jak.", "strong":" Vodu umíme upravit tak, aby bylo mytí co nejúčinnější a vaše myčka chráněná před usazováním vodního kamene. ", "text2":"Například pro mytí skla rádi používáme reverzní osmózu. Umíme ale pracovat s celou škálou řešení pro vyladění obsahu a tvrdosti vody ve vašem provozu. Podle vašich konkrétních potřeb zvolíme vhodný způsob úpravy vody, od filtračních patron až po centrální změkčení. No prostě si s tím poradíme."},
    "servis":{"title": "SERVIS", "text1": "Postaráme se o bezproblémové fungování vaší myčky. Nezáleží na tom, jestli je zbrusu nová nebo už má něco za sebou. Věk je jenom číslo a s naší odbornou péčí ani stará myčka nerezaví. Poskytujeme záruční i pozáruční servis mycích strojů značek Hobart a Winterhalter. Pravidelnou péčí dokážeme předcházet nečekaným poruchám. Ano, i u myček platí, že prevence je lepší než léčba. Umíme ale řešit i akutní situace. ", "strong":"Když Vám teče do bot, jsme připraveni hodit záchranný kruh. ", "text2":"Víme, že u servisu je důležitá kvalita i rychlost, proto máme výrobcem doporučené náhradní díly skladem. Ať už má vaše myčka jakýkoliv problém, postaráme se o ni, jako by byla naše vlastní. Spokojenost našich klientů je pro nás na prvním místě."}}
    const service = useRef(null);
    const isInViewportService = useIsInViewport(service);
    const scrollToServices = useRef();
    const scrollToReferences = useRef();
    const scrollToContacts = useRef();
    const isInViewportReferences = useIsInViewport(scrollToReferences);
    const isInViewportContacts = useIsInViewport(scrollToContacts);
    const eshop = useRef(null);
    const isInViewportEshop = useIsInViewport(eshop);
    const help = useRef(null);
    const isInViewportHelp = useIsInViewport(help);
    const [menuOpen, setMenuOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogText, setDialogText] = useState("odbornost");
    const [expertiseOpen, setExpertiseOpen] = useState(false);
    const [resourceOpen, setResourceOpen] = useState(false);
    const [waterOpen, setWaterOpen] = useState(false);
    const [serviceOpen, setServiceOpen] = useState(false);
    const [playAnimation, setPlayAnimation] = useState(false);
    const toggleMenu = () => {setMenuOpen(bool => !bool)}
    const toggleExpertise = () => {setExpertiseOpen(bool => !bool)}
    const toggleResource = () => {setResourceOpen(bool => !bool)}
    const toggleWater = () => {setWaterOpen(bool => !bool)}
    const toggleService = () => {setServiceOpen(bool => !bool)}
    const toggleDialog = (e) => {
        setDialogText(e.target.id);
        setDialogOpen(bool => !bool);}
    const dialogNext = () => {
        if(dialogText==="servis"){
            setDialogText("odbornost")
        }
        else{
            let keys = Object.keys(services);
            let index = keys.indexOf(dialogText);
            setDialogText(keys[index+1])
        }
    }
    const dialogPrev = () => {
        if(dialogText==="odbornost"){
            setDialogText("servis")
        }
        else{
            let keys = Object.keys(services);
            let index = keys.indexOf(dialogText);
            setDialogText(keys[index-1])
        }
    }
    const closeDialog = () => setDialogOpen(false);
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setDialogOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const dialogInside = useRef(null);
    useOutsideAlerter(dialogInside);
    const contentWrapper = useRef(null);
    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);

        const observer = useMemo(
            () =>
                new IntersectionObserver(([entry]) =>
                    setIsIntersecting(entry.isIntersecting),
                ),
            [],
        );

        useEffect(() => {
            observer.observe(ref.current);

            return () => {
                observer.disconnect();
            };
        }, [ref, observer]);

        return isIntersecting;
    }
    const [isHoverEshop, setIsHoverEshop] = useState(false);
    const handleMouseEnter = () => {
        setIsHoverEshop(true);
    };
    const handleMouseLeave = () => {
        setIsHoverEshop(false);
    };
    const parallax1 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -100], speed: 10 });
    const parallax2 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -300], speed: 100 });
    const parallax3 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -100], speed: 10 });
    const parallax4 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -500], speed: 10 });
    const parallax5 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -200], speed: 10 });
    const parallax6 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -100], speed: 100 });
    const parallax7 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -600], speed: 10 });
    const parallax8 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -900], speed: 10 });
    const parallax9 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -200], speed: 100 });
    const parallax10 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -100], speed: 10 });
    const parallax11 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -1000], speed: 1000 });
    const parallax12 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -500], speed: 100 });
    const parallax13 = useParallax({startScroll: 0, endScroll: 620, translateY: [0, -200], speed: 10 });
    const parallaxController = useParallaxController();
    const handleLoad = () => parallaxController.update();
    useKeypress(['ArrowLeft', 'ArrowRight'], (event) => {
        if (event.key === 'ArrowLeft') {
            dialogPrev()
        } else {
            dialogNext()
        }
    });
    useEffect(() => {
        if (!playAnimation) {
            setTimeout(function() {
                setPlayAnimation(true)
            }, 1000);
        }
    }, []);
  return (
    <>
        {!playAnimation && (<div className="loadingPage">
            <div className="ld-ripple">
                <div></div>
                <div></div>
            </div>
        </div>)}
        <div className="menuBar">
            <div className="content">
                <img className={"companyName"} src={logo} alt={"logo"} height={60} width={"auto"} onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}/>
                <div className="flexBoxDesktop">
                    <div className={isInViewportService ? " menuBarButtonFocus" : "menuBarButton"} onClick={() => scrollToServices.current.scrollIntoView({ behavior: 'smooth' })}>NAŠE SLUŽBY</div>
                    <div className={isInViewportReferences && !isInViewportService ? " menuBarButtonFocus" : "menuBarButton"} onClick={() => scrollToReferences.current.scrollIntoView({ behavior: 'smooth' })}>REFERENCE</div>
                    <div className={isInViewportContacts && !isInViewportReferences ? " menuBarButtonFocus" : "menuBarButton"} onClick={() => scrollToContacts.current.scrollIntoView({ behavior: 'smooth' })}>KONTAKTY</div>
                    <div className="menuBarEshopButton"><a href="https://shop.odbornamyckova.cz/" target="_blank" rel="noopener noreferrer">E-SHOP</a></div>
                </div>
                <a href="tel:+420724100111"><img  src={hotlineLight} alt={"hotline"} className={"menuBarHotlineDesktop"} width={"auto"}/></a>
                <img src={menu} alt={"menu icon"} className="menuMobile" width={32} height={32} onClick={toggleMenu}/>
            </div>
        </div>
        {menuOpen && (<div className="menuWrapperMobile">
            <div className="menuBoxMobileOutside" onClick={toggleMenu}/>
            <div className="menuBoxMobile">
                <div className="menuBarButtonMobile plr" onClick={() => {scrollToServices.current.scrollIntoView({ behavior: 'smooth' }); toggleMenu();}}>NAŠE SLUŽBY</div>
                <div className="menuBarButtonMobile plr" onClick={() => {scrollToReferences.current.scrollIntoView({ behavior: 'smooth' }); toggleMenu();}}>REFERENCE</div>
                <div className="menuBarButtonMobile plr" onClick={() => {scrollToContacts.current.scrollIntoView({ behavior: 'smooth' }); toggleMenu();}}>KONTAKTY</div>
                <a href="https://shop.odbornamyckova.cz/" target="_blank" className="menuBarEshopButton plr"><a rel="noopener noreferrer">E-SHOP</a></a>
            </div>
        </div>)}
        {dialogOpen && (<div className="dialog">
            <div
                className="modalBox" ref={dialogInside}
            >
                <div className="modalImage">
                    <div className="modalCloseButtonBox">
                        <img className="modalCloseButton" alt={"close button"} src={close} onClick={closeDialog}/>
                    </div>
                    <div className="modalLine"/>
                    <span className="modalTextMain">{services[dialogText].title}</span>
                </div>
                <div className="modatTextBox">
                    <img src={arrowRightPrimary} alt={"arrow button previous"} className="prev" onClick={dialogPrev}/>
                    <div className="modalTextBoxFont" >
                        {services[dialogText].text1}
                        <strong>{services[dialogText].strong}</strong>
                        {services[dialogText].text2}
                    </div>
                    <img src={arrowRightPrimary} alt={"arrow button next"} className="next" onClick={dialogNext}/>
                </div>
            </div>
        </div>)}
        <div className={classNames('heroButtonBox', { heroButtonBoxAnimation: playAnimation })} onClick={() => scrollToServices.current.scrollIntoView({ behavior: 'smooth' })}>
            <img src={arrowRightPrimary} alt={"arrow button right"} className="heroButton"/>
        </div>
        <div className="bubblesBox">
            <img src={bubblesLogo} alt={"bubbles"} className={classNames('bubblesLogo', { logoAnimation: playAnimation })} height={"400px"} />
            <img src={bubble1} className={classNames('bubble1', { bubblesAnimation: playAnimation })} height={"70px"} ref={parallax1.ref} onLoad={handleLoad}/>
            <img src={bubble2} className={classNames('bubble2', { bubblesAnimation: playAnimation })} height={"40px"} ref={parallax2.ref} onLoad={handleLoad}/>
            <img src={bubble3} className={classNames('bubble3', { bubblesAnimation: playAnimation })} height={"20px"} ref={parallax3.ref} onLoad={handleLoad}/>
            <img src={bubble4} className={classNames('bubble4', { bubblesAnimation: playAnimation })} height={"70px"} ref={parallax4.ref} onLoad={handleLoad}/>
            <img src={bubble5} className={classNames('bubble5', { bubblesAnimation: playAnimation })} height={"90px"} ref={parallax5.ref} onLoad={handleLoad}/>
            <img src={bubble6} className={classNames('bubble6', { bubblesAnimation: playAnimation })} height={"110px"} ref={parallax6.ref} onLoad={handleLoad}/>
            <img src={bubble4} className={classNames('bubble7', { bubblesAnimation: playAnimation })} height={"20px"} ref={parallax7.ref} onLoad={handleLoad}/>
            <img src={bubble5} className={classNames('bubble8', { bubblesAnimation: playAnimation })} height={"80px"} ref={parallax8.ref} onLoad={handleLoad}/>
            <img src={bubble2} className={classNames('bubble9', { bubblesAnimation: playAnimation })} height={"70px"} ref={parallax9.ref} onLoad={handleLoad}/>
            <img src={bubble1} className={classNames('bubble10', { bubblesAnimation: playAnimation })} height={"80px"} ref={parallax10.ref} onLoad={handleLoad}/>
            <img src={bubble3} className={classNames('bubble11', { bubblesAnimation: playAnimation })} height={"20px"} ref={parallax11.ref} onLoad={handleLoad}/>
            <img src={bubble5} className={classNames('bubble12', { bubblesAnimation: playAnimation })} height={"80px"} ref={parallax12.ref} onLoad={handleLoad}/>
            <img src={bubble3} className={classNames('bubble13', { bubblesAnimation: playAnimation })} height={"20px"} ref={parallax13.ref} onLoad={handleLoad}/>
        </div>

        <h2 className={isInViewportService ? "lazyLoading sevriceTitle" : "sevriceTitle"} ref={scrollToServices}>NAŠE SLUŽBY</h2>
        <div className={isInViewportService ? "lazyLoading sevriceBoxs" : "sevriceBoxs"} ref={service}>
            <div className="sevriceBoxsContentDesktop">
                <div className="serviceExpertise" id="odbornost" onClick={toggleDialog}><img src={plus} id="odbornost" alt={"expertise service"} className="servicePlusButton" height={30} width={30}/></div>
                <div className="serviceResource" id="prostredky" onClick={toggleDialog}><img src={plus} id="prostredky" alt={"resourse service"} className="servicePlusButton" height={30} width={30}/></div>
                <div className="serviceWater" id="voda" onClick={toggleDialog}><img src={plus} id="voda" alt={"watter service"} className="servicePlusButton" height={30} width={30}/></div>
                <div className="serviceService" id="servis" onClick={toggleDialog}><img src={plus} id="servis" alt={"servise"} className="servicePlusButton" height={30} width={30}/></div>
            </div>
            <div className="sevriceBoxsContentMobile">
                <div className="serviceExpertise" id="odbornost" onClick={toggleExpertise}><img src={plus} alt={"expertise service"} className={expertiseOpen ? "servicePlusButton crossRotation" : "servicePlusButton"} height={30} width={30}/></div>
                {expertiseOpen && (<div className="serviceTextMobile montserratRegular">{services.odbornost.text1}<strong>{services.odbornost.strong}</strong>{services.odbornost.text2}</div>)}
                <div className="serviceResource" id="prostredky" onClick={toggleResource}><img src={plus} alt={"resourse service"} className={resourceOpen ? "servicePlusButton crossRotation" : "servicePlusButton"} height={30} width={30}/></div>
                {resourceOpen && (<div className="serviceTextMobile montserratRegular">{services.prostredky.text1}<strong>{services.prostredky.strong}</strong>{services.prostredky.text2}</div>)}
                <div className="serviceWater" id="voda" onClick={toggleWater}><img src={plus} alt={"watter service"} className={waterOpen ? "servicePlusButton crossRotation" : "servicePlusButton"} height={30} width={30}/></div>
                {waterOpen && (<div className="serviceTextMobile montserratRegular">{services.voda.text1}<strong>{services.voda.strong}</strong>{services.voda.text2}</div>)}
                <div className="serviceService" id="servis" onClick={toggleService}><img src={plus} alt={"servise"} className={serviceOpen ? "servicePlusButton crossRotation" : "servicePlusButton"} height={30} width={30}/></div>
                {serviceOpen && (<div className="serviceTextMobile montserratRegular">{services.servis.text1}<strong>{services.servis.strong}</strong>{services.servis.text2}</div>)}
            </div>
        </div>
        <div className={isInViewportEshop ? "lazyLoading eshopBox" : "eshopBox"} ref={eshop}>
            <div className="eshopBoxContent">
                <div className="eshopTextBox">
                    <div className="eshopLine"/>
                    <h2 className="eshopTextMain">NAVŠTIVTE NÁŠ NOVĚ <br/>NALEŠTĚNÝ E-SHOP</h2>
                    <div className="eshopCartBox">
                        <img src={shoppingCart} alt={"shopping cart icon"} height={54} width={"auto"} className="eshopCartImage"/>
                        <span className="eshopText">A objednávejte <br/>jednoduše on-line.</span>
                    </div>
                </div>
                <div className="eshopButtonBox">
                    <a href="https://shop.odbornamyckova.cz/" target="_blank" className="eshopButton" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}><span className="eshopButtonText">NAKUPOVAT</span><img className={isHoverEshop ? "eshop eshopHover" : "eshop"} src={arrowRight} alt={"arrow button right"}/></a>
                </div>
            </div>
        </div>
        <div className={isInViewportReferences ? "lazyLoading referenceBox" : "referenceBox"} ref={scrollToReferences} >
            <div className="referenceBoxContent">
                <div className="referenceLine"/>
                <h2 className="referenceTextMain">REFERENCE</h2>
                <ReferenceContainer companyName="PRAGUE MARRIOTT HOTEL" referenceText="Děkujeme za skvělou spolupráci s Odbornou myčkovou. Profesionální myčky jsou nedílnou součástí našeho hotelu, proto potřebujeme, abychom se mohli spolehnout na jejich bezproblémové fungování. S Odbornou myčkovou můžeme." referencerName="Jan Červený" referencerPosition="Manažer hotelu" image={marriott} hidden/>
                <ReferenceContainerReverse companyName="STŘEDNÍ ŠKOLA GASTRONOMIE U KRBU" referenceText="Díky Odborné myčkové vidí naši studenti, jak má vypadat dobře fungující myčka a kvalitní servis. Velice  oceňujeme možnost zakonzervování myček před prázdninami. Odbornou myčkovou rozhodně můžeme doporučit." referencerName="Alena Kalenská" referencerPosition="Majitelka" image={ssg} hidden/>
                <h2 className="referenceNextText">A DALŠÍ SPOKOJENÍ KLIENTI</h2>
                <div id="container">
                    <div className="photobanner">
                        <img src={uMateje} alt={"U Matěje"} className="image"/>
                        <img src={cukrar} alt={"Cukrář"} className="image"/>
                        <img src={MSP} alt={"Mama Shelter Prague"} className="image"/>
                        <img src={McD} alt={"McDonalds"} className="image"/>
                        <img src={courtyard} alt={"Courtyard"} className="image"/>
                        <img src={O2Arena} alt={"O2Arena"} className="image"/>
                        <img src={O2Universum} alt={"O2Universum"} className="image"/>
                        <img src={stagesHotel} alt={"Stages Hotel"} className="image"/>
                        <img src={kkHotels} alt={"K+K Hotels"} className="image"/>
                        <img src={cervenyJelen} alt={"Červený Jelen"} className="image"/>
                        <img src={trautenberk} alt={"Pivovar Trautenberk"} className="image"/>
                        <img src={uMateje} alt={"U Matěje"} className="image"/>
                        <img src={cukrar} alt={"Cukrář"} className="image"/>
                        <img src={MSP} alt={"Mama Shelter Prague"} className="image"/>
                        <img src={McD} alt={"McDonalds"} className="image"/>
                        <img src={courtyard} alt={"Courtyard"} className="image"/>
                        <img src={O2Arena} alt={"O2Arena"} className="image"/>
                        <img src={O2Universum} alt={"O2Universum"} className="image"/>
                        <img src={stagesHotel} alt={"Stages Hotel"} className="image"/>
                        <img src={kkHotels} alt={"K+K Hotels"} className="image"/>
                        <img src={cervenyJelen} alt={"Červený Jelen"} className="image"/>
                        <img src={trautenberk} alt={"Pivovar Trautenberk"} className="image"/>
                    </div>
                </div>
                <div className="mobileNextClients">
                    <img src={uMateje} alt={"U Matěje"} className="image"/>
                    <img src={cukrar} alt={"Cukrář"} className="image"/>
                    <img src={MSP} alt={"Mama Shelter Prague"} className="image"/>
                    <img src={McD} alt={"McDonalds"} className="image"/>
                    <img src={courtyard} alt={"Courtyard"} className="image"/>
                    <img src={O2Arena} alt={"O2Arena"} className="image"/>
                    <img src={O2Universum} alt={"O2Universum"} className="image"/>
                    <img src={stagesHotel} alt={"Stages Hotel"} className="image"/>
                    <img src={kkHotels} alt={"K+K Hotels"} className="image"/>
                    <img src={cervenyJelen} alt={"Červený Jelen"} className="image"/>
                    <img src={trautenberk} alt={"Pivovar Trautenberk"} className="image"/>
                </div>
            </div>
        </div>
        <div className={isInViewportContacts ? "lazyLoading contactBox" : "contactBox"} ref={scrollToContacts}>
            <div className="contactContent">
                <div className="contentInfoBox">
                    <div className="contactLine"/>
                    <h2 className="contactTextMain">KONTAKT</h2>
                    <span className="contactText">Jsme tu pro vás. <br/>Zeptejte se.</span>
                    <div className="contactTitleInfo">OBECNÉ INFORMACE</div>
                    <a href={"mailto:info@odbornamyckova.cz"} className="contactTextInfo">info@odbornamyckova.cz</a>
                    <div className="contactTitleInfo">OBJEDNÁVKY</div>
                    <a href={"mailto:objednavky@odbornamyckova.cz"} className="contactTextInfo">objednavky@odbornamyckova.cz</a>
                    <div className="contactTitleInfo">FAKTURACE</div>
                    <a href={"mailto:fakturace@odbornamyckova.cz"} className="contactTextInfo">fakturace@odbornamyckova.cz</a>
                    <div className="contactTitleInfo">ADRESA</div>
                    <span className="contactTextInfo">Odborná myčková, a.s.<br/>IČ 07278926 / DIČ CZ07278926 <br/>Na Vinobraní 1792/55, 106 00 Praha 10 — Záběhlice<br/>Městský soud v Praze</span>
                </div>
                <div className="contentInfoBox">
                    <ContactPersonComponent contactPerson={contactPerson} contactQRC={contactQRCRosin} name="ALEŠ ROSÍN" position="Ředitel společnosti" tel="+420 602 682 595" email="rosin@odbornamyckova.cz"/>
                    <ContactPersonComponent contactPerson={contactPerson2} contactQRC={contactQRCPolacek} name="PETR POLÁČEK" position="Technický garant společnosti" tel="+420 602 392 307" email="polacek@odbornamyckova.cz"/>
                </div>
            </div>
        </div>
        <div className={isInViewportHelp ? "lazyLoading helpBox" : "helpBox"} ref={help}>
            <div className="helpContent">
                <div className="helpInfoBox">
                    <div className="helpLine"/>
                    <h2 className="helpTextMain">POTŘEBUJETE <br/>POMOC?</h2>
                    <span className="helpText">Pokud vám teče do bot a potřebujete rychlou pomoc, obraťte se na naši Servisní linku. Rádi vám pomůžeme.</span>
                    <a href="tel:+420724100111"><img  src={hotlineDark} alt={"hotline"} width={"90%"}/></a>
                </div>
                <div className="helpImageBox">
                    <img src={helpImg} alt={"help"} height="100%" width="100%"/>
                </div>
            </div>
        </div>
        <div className="bottomBox">
            <div className="bottomBoxContent">
                <div className="copyrightLinksBox">
                    <div className="copyright">&#169;Copyright 2023</div>
                    <div className="rights">Všechna práva vyhrazena</div>
                </div>
                <div className="copyrightLinksBox">
                    <a href={vop} className="link" target='_blank' rel='noopener noreferrer'>Všeobecné obchodní podmínky</a>
                    <a href={gdpr} className="linkLines" target='_blank' rel='noopener noreferrer'>Zásady ochrany osobních údajů (GDPR)</a>
                </div>
            </div>
        </div>
    </>
  );
}

export default Homepage;
