import './ReferenceContainer.css';



function ReferenceContainerReverse(props) {
    return (
        <div className="containerReverse">
            <div className="imageBox">
                <div className="lineBetweenReverse">
                    <img src={props.image} alt={"referencer company logo"} height={144} width="auto"/>
                </div>
            </div>
            <div className="contentBoxReverse">
                <span className="referenceCompanyName">{props.companyName}</span>
                <div className="referenceText">{props.referenceText}</div>
                <div className={props.hidden? "referencerNameBox hidden" : "referencerNameBox"}>
                    <div className="dash"/>
                    <span className="referencerName">{props.referencerName}</span>
                </div>
                <span className={props.hidden? "referencerPosition hidden" : "referencerPosition"}>{props.referencerPosition}</span>
            </div>
        </div>
    );
}

export default ReferenceContainerReverse;